import React from "react";
import styled, { keyframes } from "styled-components";

function LogosSection() {
  const logos = [
    "/assets/logos/logo_bnp.svg",
    "/assets/logos/logo_betagov.svg",
    "/assets/logos/logo_bouyues.svg",
    "/assets/logos/logo_ekimetrics.svg",
    "/assets/logos/logo_ingenico.svg",
    "/assets/logos/logo_laredoute.svg",
    "/assets/logos/logo_mazars.svg",
    "/assets/logos/logo_rakuten.svg",
    "/assets/logos/logo_totalenergies.svg",
  ];

  return (
    <ScrollingWrapper>
      <Header>
        <Text>Ils nous ont fait confiance</Text>
        <Line />
      </Header>
      <LogosContent>
        {logos.map((logo, index) => (
          <img key={index} src={logo} alt={`logo ${index}`} />
        ))}
        {logos.map((logo, index) => (
          <img
            key={`duplicate-${index}`}
            src={logo}
            alt={`logo duplicate ${index}`}
          />
        ))}
      </LogosContent>
    </ScrollingWrapper>
  );
}

export default LogosSection;

const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const ScrollingWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Header = styled.div`
  width: 90%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 5%;
`;
const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #dcdcdc;
`;
const Text = styled.text`
  color: var(#bdbdbd);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  @media (max-width: 780px) {
    font-size: 18px;
  }
`;

const LogosContent = styled.div`
  display: flex;
  gap: 24px;
  animation: ${scroll} 20s linear infinite;
  align-items: center;
  width: calc(200%);
  @media (max-width: 780px) {
    gap: 12px;
  }

  img {
    height: 130px;
    object-fit: contain;
    display: block;
    @media (max-width: 780px) {
      height: 60px;
    }
  }
`;
